import {
  ADD_ROOM_ALLOWED,
  ADD_ROOM_DENIED,
  ADD_ROOM_REQUESTED,
  BOOKING_ADD_ROOM_SUCCEEDED,
  BOOKING_ADD_SERVICE,
  BOOKING_REMOVE_SERVICE,
  BOOKING_UPDATE_STAY,
  CANCEL_BOOKING_FAILED,
  CANCEL_BOOKING_SUCCEEDED,
  FETCH_BOOKING_FAILED,
  FETCH_BOOKING_NOT_FOUND,
  FETCH_BOOKING_REQUESTED,
  FETCH_BOOKING_SUCCEEDED,
  MAKE_BOOKING_REQUESTED,
  MAKE_BOOKING_SUCCEEDED,
} from '../actionTypes';


export const initialState = {
  additionalRoomRequestInProgress: false,
  additionalRoomUnavailable: false,
  additionalRooms: {},
  details: {},
  findBookingInProgress: false,
  reservation: null,
  retrievedReservation: null,
  services: {},
  stay: null,
};

export default function booking(state = initialState, action) {
  const origState = { ...state };
  switch (action.type) {
    case ADD_ROOM_REQUESTED:
      return {
        ...state,
        additionalRoomRequestInProgress: true,
      };
    case ADD_ROOM_ALLOWED:
      return {
        ...state,
        additionalRoomUnavailable: false,
        additionalRoomRequestInProgress: false,
      };
    case ADD_ROOM_DENIED:
      return {
        ...state,
        additionalRoomUnavailable: true,
        additionalRoomRequestInProgress: false,
      };
    case BOOKING_ADD_ROOM_SUCCEEDED:
      return {
        ...state,
        additionalRooms: {
          ...state.additionalRooms,
          [action.payload.roomId]: {
            adults: action.payload.numAdults,
            children: action.payload.numChildren,
            services: {},
            roomId: action.payload.roomId,
          },
        },
      };
    case BOOKING_ADD_SERVICE:
      if (action.roomId) {
        return {
          ...state,
          additionalRooms: {
            ...state.additionalRooms,
            [action.roomId]: {
              ...state.additionalRooms[action.roomId],
              services: {
                ...state.additionalRooms[action.roomId].services,
                [action.payload.code]: action.payload,
              },
            },
          },
        };
      }
      return {
        ...state,
        services: {
          ...state.services,
          [action.payload.code]: action.payload,
        },
      };
    case BOOKING_REMOVE_SERVICE:
      if (action.roomId) {
        delete origState.additionalRooms[action.roomId].services[action.payload.code];
      } else {
        delete origState.services[action.payload.code];
      }
      return origState;
    case BOOKING_UPDATE_STAY:
      return {
        ...state,
        stay: action.stay,
        services: {},
        additionalRooms: {},
        additionalRoomUnavailable: false,
        reservation: null,
      };
    case MAKE_BOOKING_REQUESTED:
      return {
        ...state,
        details: action.details,
      };
    case MAKE_BOOKING_SUCCEEDED:
      return {
        ...initialState,
        stay: state.stay, // Needed for confirmation page
        details: state.details, // Needed for confirmation page
        services: state.services, // Needed for confirmation page
        reservation: action.reservation,
      };
    case FETCH_BOOKING_REQUESTED:
      return {
        ...state,
        findBookingInProgress: true,
      };
    case FETCH_BOOKING_SUCCEEDED:
      return {
        ...state,
        findBookingInProgress: false,
        retrievedReservation: action.reservation,
      };
    case FETCH_BOOKING_NOT_FOUND:
    case FETCH_BOOKING_FAILED:
      return {
        ...state,
        findBookingInProgress: false,
      };
    case CANCEL_BOOKING_SUCCEEDED:
      return {
        ...state,
        retrievedReservation: {
          ...state.retrievedReservation,
          cancellationError: false,
          cancellationId: action.payload.confirmationId,
        },
      };
    case CANCEL_BOOKING_FAILED:
      return {
        ...state,
        retrievedReservation: {
          ...state.retrievedReservation,
          cancellationError: true,
        },
      };
    default:
      return state;
  }
}

export const getAdditionalRooms = (state) => state.additionalRooms;
export const getBookingDetails = (state) => state.details;
export const getReservation = (state) => state.reservation;
export const getRetrievedReservation = (state) => state.retrievedReservation;
export const getServicesList = (state) => Object.values(state.services);
export const getStay = (state) => (state.stay ? {
  ...state.stay,
  numRooms: (!state.stay || !Object.keys(state.stay).length) ? 0 : Object.keys(state.additionalRooms).length + 1,
} : null);
export const isFindBookingInProgress = (state) => !!state.findBookingInProgress;
export const isRoomRequestInProgress = (state) => !!state.additionalRoomRequestInProgress;
