import {
  ADD_ROOM_REQUESTED, CANCEL_BOOKING_REQUESTED, CLOSE_NAV,
  FETCH_AVAILABILITY_REQUESTED, FETCH_AVAILABILITY_RETRIED,
  FETCH_BOOKING_REQUESTED, FETCH_SERVICES_REQUESTED, FETCH_SERVICES_RETRIED,
  MAKE_BOOKING_REQUESTED, SHOW_ERROR, TOGGLE_NAV, UNSET_ERROR, WINDOW_RESIZE,
} from '../actionTypes';


const initialState = {
  error: null,
  navOpen: false,
  retryAction: null,
  windowSize: 'xxs',
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_NAV:
      return {
        ...state,
        navOpen: !state.navOpen,
      };
    case CLOSE_NAV:
      return {
        ...state,
        navOpen: false,
      };
    case WINDOW_RESIZE:
      return {
        ...state,
        windowSize: action.payload || 'xxs',
      };
    case SHOW_ERROR:
      return {
        ...state,
        retryAction: action.action,
        error: action.error,
      };
    case FETCH_AVAILABILITY_REQUESTED:
    case FETCH_AVAILABILITY_RETRIED:
    case FETCH_SERVICES_REQUESTED:
    case FETCH_SERVICES_RETRIED:
    case ADD_ROOM_REQUESTED:
    case MAKE_BOOKING_REQUESTED:
    case FETCH_BOOKING_REQUESTED:
    case CANCEL_BOOKING_REQUESTED:
    case UNSET_ERROR:
      return {
        ...state,
        error: null,
        retryAction: null,
      };
    default:
      return state;
  }
}

export const getError = (state) => state.error;
export const getNavOpen = (state) => state.navOpen;
export const getRetryAction = (state) => state.retryAction;
export const getWindowSize = (state) => state.windowSize;
