import Cookie from 'js-cookie';

import { COOKIE_CONSENT } from '../constants/cookies';


export const getCookieConsent = () => {
  const defaultCookieConsent = {
    analytics: false,
    marketing: false,
  };

  const cookieValue = Cookie.get(COOKIE_CONSENT);

  if (typeof cookieValue !== 'string') {
    return defaultCookieConsent;
  }

  try {
    const consent = JSON.parse(cookieValue);

    return {
      analytics: consent.analytics === true,
      marketing: consent.marketing === true,
    };
  } catch {
    return defaultCookieConsent;
  }
};
