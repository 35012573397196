import { breakpoints, viewportWidth } from '../constants/mediaqueries';
import { REM_SIZE } from '../constants/sizes';


/**
 * Mixin to create stretchy font sizing
 * See https://fvsch.com/code/css-locks/ for the logic.
 */

// Resolves to 0-1rem, depending on the viewport width between the xs and xl breakpoints
const r = `(100vw - ${viewportWidth.xs}rem) / (${viewportWidth.xl} - ${viewportWidth.xs})`;
// Resolves to 1-0rem
const rInverse = `(100vw - ${viewportWidth.xl}rem) / (${viewportWidth.xs} - ${viewportWidth.xl})`;

export function generateFontSizes(startSize, startLH, endSize, endLH) {
  // Args are provided in px, but we want to work with font-size in rems
  const startFontSize = startSize / REM_SIZE;

  // And with line-height in ems
  const startLineHeight = startLH / startSize;

  // Allow for fonts to not scale at all, and return early
  const nonScaledStyles = `
    font-size: ${startFontSize}rem;
    line-height: ${startLineHeight}em;
  `;
  if (typeof (endSize) === 'undefined' || typeof (endLH) === 'undefined') {
    return nonScaledStyles;
  }

  // Otherwise, continue and convert the end sizes to the correct units
  const endFontSize = endSize / REM_SIZE;
  const endLineHeight = endLH / endSize;

  // Return early if the start and end sizes are the same
  if (startFontSize === endFontSize && startLineHeight === endLineHeight) {
    return nonScaledStyles;
  }

  // Calculate font-size
  const fontSizeIncrease = endFontSize - startFontSize;
  const fontSize = `
    font-size: ${startFontSize}rem;
    @media ${breakpoints.xs} {
      font-size: calc(${startFontSize}rem + ${fontSizeIncrease} * ${r});
    }
  `;

  // If the line-height doesn't change, we can return early
  if (endLineHeight === startLineHeight) {
    return `
      ${fontSize}
      line-height: ${startLineHeight}em;
    `;
  }

  // We have very different results depending on whether the line-height
  // increases or decreases in proportion to the font size
  let lineHeight;
  if (endLineHeight > startLineHeight) {
    const baseValue = startLineHeight;
    const upperDataPoint = endSize * (endLineHeight - startLineHeight); // px
    const lineHeightIncreaseRem = upperDataPoint / REM_SIZE;

    lineHeight = `
      line-height: ${baseValue}em;
      @media ${breakpoints.xs} {
        line-height: calc(${baseValue}em + ${lineHeightIncreaseRem} * ${r});
      }
    `;
  } else {
    const baseValue = endLineHeight;
    const lowerDataPoint = startSize * (startLineHeight - endLineHeight); // px
    const lineHeightDecreaseRem = lowerDataPoint / REM_SIZE;

    lineHeight = `
      line-height: calc(${baseValue}em + ${lineHeightDecreaseRem} * 1rem);
      @media ${breakpoints.xs} {
        line-height: calc(${baseValue}em + ${lineHeightDecreaseRem} * ${rInverse});
      }
    `;
  }

  return `
    ${fontSize}
    ${lineHeight}
  `;
}
