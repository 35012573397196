/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';


export const sagaMiddleware = createSagaMiddleware();

// If we're in a browser environment and we're in development mode
const composeEnhancers = typeof window !== 'undefined'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  && process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

export const createStoreWithMiddleware = () => createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
