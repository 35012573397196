/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';

import { PersistGate } from 'redux-persist/integration/react';

import { getLocaleFromURI } from './src/helpers/location';
import { createStoreWithMiddleware as createStore, sagaMiddleware } from './src/state';
import { SEARCH_PARAMS_UPDATED, ROUTER_LOCATION_CHANGED } from './src/state/actionTypes';
import rootSaga from './src/state/sagas';
import { gaInit } from './src/helpers/ga';
import { fbqInit, fbqTrackPageview } from './src/helpers/meta';


const store = createStore();
const persistor = persistStore(store);

const transitionDelay = 250;

let locale;

gaInit();
fbqInit();

export const wrapRootElement = ({ element }) => {
  const ConnectedRootElement = (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
  return ConnectedRootElement;
};

export const onClientEntry = () => {
  require('whatwg-fetch'); // eslint-disable-line global-require
  sagaMiddleware.run(rootSaga);
};

export const onRouteUpdate = ({ location }) => {
  const newLocale = getLocaleFromURI(location.pathname);
  if (locale !== newLocale) {
    store.dispatch({ type: SEARCH_PARAMS_UPDATED, payload: { locale: newLocale } });
    locale = newLocale;
  }
  store.dispatch({ type: ROUTER_LOCATION_CHANGED, payload: { location: location.pathname } });
  fbqTrackPageview();
};

// https://github.com/gatsbyjs/gatsby/issues/5213#issuecomment-428368131
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'POP') {
    const previousPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(previousPosition || [0, 0])),
      transitionDelay,
    );
  } else {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  }
  return false;
};
