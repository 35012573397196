import moment from 'moment';

import { API_ORIGIN } from '../constants/api';


const apiURL = `${API_ORIGIN}/api`;
const version = '1.2.5';
const defaultHotelCode = '25214';

const availabilityEndpoint = `${apiURL}/avail`;
const bookingEndpoint = `${apiURL}/book`;
const infoEndpoint = `${apiURL}/info`;
const servicesEndpoint = `${apiURL}/services`;
const readEndpoint = `${apiURL}/read`;
const cancelEndpoint = `${apiURL}/cancel`;

const minPayload = {
  token: 'jJkvPCTK3rjuLWForY4mUnpfWyv4fi9p',
  version,
};

const calculateDuration = (arrival, departure) => {
  // Default to 1 night if either date is invalid
  try {
    const start = moment(arrival);
    const end = moment(departure);
    return end.diff(start, 'days');
  } catch (error) {
    return 1;
  }
};

const sendRequest = (url, body) => fetch(url, {
  body: JSON.stringify(body),
  headers: {
    'Content-Type': 'application/json',
  },
  method: 'POST',
})
  .then((resp) => resp.json())
  .then((data) => Promise.resolve({ data }))
  .catch((error) => ({ error }));

const createStandardPayload = ({
  hotelCode = defaultHotelCode, language = 'en', arrivalDate = moment().format('YYYY-MM-DD'),
  departureDate, duration, numAdults = 1, numChildren = 0, numRooms = 1, roomType, ratePlan,
}) => {
  if (!duration) {
    duration = calculateDuration(arrivalDate, departureDate); // eslint-disable-line no-param-reassign
  }

  const payload = {
    hotel_code: hotelCode,
    adults: numAdults,
    arrival_date: arrivalDate,
    children: numChildren,
    duration,
    language,
    room_count: numRooms,
    results: 'all',
  };

  if (roomType) {
    payload.room_type = roomType;
  }
  if (ratePlan) {
    payload.rate_plan = ratePlan;
  }

  return { ...minPayload, ...payload };
};

const fetchHotelInfo = (hotelCode = defaultHotelCode) => {
  const payload = {
    ...minPayload,
    hotel_code: hotelCode,
  };
  return sendRequest(infoEndpoint, payload);
};

const fetchBooking = ({ confirmationNumber, hotelCode = defaultHotelCode }) => {
  const payload = {
    ...minPayload,
    hotel_code: hotelCode,
    confirm_number: confirmationNumber,
  };
  return sendRequest(readEndpoint, payload);
};

const cancelBooking = ({ confirmationNumber, hotelCode = defaultHotelCode }) => {
  const payload = {
    ...minPayload,
    hotel_code: hotelCode,
    confirm_number: confirmationNumber,
    confirm: 'BookAPI_CXL',
  };
  return sendRequest(cancelEndpoint, payload);
};

const fetchGeneralAvailability = ({
  hotelCode = defaultHotelCode, language = 'en', numDays = 60, arrivalDate = moment().format('YYYY-MM-DD'),
  departureDate, duration,
}) => {
  if (!duration) {
    duration = calculateDuration(arrivalDate, departureDate); // eslint-disable-line no-param-reassign
  }
  const payload = {
    ...minPayload,
    hotel_code: hotelCode,
    arrival_date: arrivalDate,
    duration,
    days_to_shop: numDays,
    summary_only: 'true',
    hotel_stay_only: 'true',
    language,
    quantity: 1,
    results: 'all',
  };

  return sendRequest(availabilityEndpoint, payload);
};

const fetchAvailability = (params) => {
  const body = createStandardPayload(params);

  const { promoCode } = params;
  if (promoCode) {
    body.promo_code = promoCode;
  }

  return sendRequest(availabilityEndpoint, body);
};

const fetchServices = (params) => {
  const body = createStandardPayload(params);
  return sendRequest(servicesEndpoint, body);
};

const makeBooking = ({ hotelCode = defaultHotelCode, language = 'en', details, services, stay }) => {
  // Stay details
  const { adults, children = 0, numRooms, ratePlan, roomType, promoCode } = stay;

  const roomStays = {
    adults,
    children,
    room_count: numRooms,
    rate_plan: promoCode ? { code: ratePlan, id: promoCode } : ratePlan,
    room_type: roomType,
  };

  // Guest details
  const {
    addressLine1,
    addressLine2,
    city,
    companyName,
    country,
    email,
    fullName,
    phone,
    postalCode,
  } = details;

  const guestDetails = {
    name: {
      surname: fullName,
    },
    telephone: {
      phone_number: phone,
    },
    email,
    address: {
      lines: [
        addressLine1,
      ],
      city,
      postcode: postalCode,
      country,
    },
  };

  // Add optional fields
  if (companyName) {
    guestDetails.address.type = 2;
    guestDetails.address.company = companyName;
  } else {
    guestDetails.address.type = 1;
  }
  if (addressLine2) {
    guestDetails.address.lines.push(addressLine2);
  }

  // Payment
  const { creditCardExpiryMonth, creditCardExpiryYear, creditCardNumber, creditCardCode, creditCardName } = details;
  const paymentCard = {
    card_number: creditCardNumber.replace(/\s/g, ''),
    expire_date: `${creditCardExpiryMonth}${creditCardExpiryYear}`,
    series_code: creditCardCode.toString(),
    card_holder_name: creditCardName,
  };

  // Extras
  const comments = [];
  const { specialReqs, arrivalTime, isBusinessStay } = details;
  if (specialReqs) {
    comments.push(specialReqs);
  }
  if (isBusinessStay) {
    comments.push('Business stay'); // TODO Localise this?
  }
  if (arrivalTime) {
    comments.push(arrivalTime); // Added by Book-a-Wizard (MH-18)
  }

  // Generic
  const { arrivalDate, duration } = stay;

  // Create payload
  const payload = {
    hotel_code: hotelCode,
    res_status: 'Commit',
    confirm: 'BookAPI_Conf',
    arrival_date: arrivalDate,
    duration,
    language,
    room_stays: roomStays,
    guests: [guestDetails],
    payment_card: paymentCard,
  };

  if (arrivalTime) {
    payload.arrival_time = arrivalTime;
  }

  if (services.length) {
    payload.services = services;
  }

  if (comments.length) {
    payload.comments = comments;
  }

  const body = { ...minPayload, ...payload };
  return sendRequest(bookingEndpoint, body);
};


export default {
  cancelBooking,
  fetchAvailability,
  fetchBooking,
  fetchGeneralAvailability,
  fetchHotelInfo,
  fetchServices,
  makeBooking,
};
