import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';

import { expireTransformBaseConfig } from '../../constants/state';

import availability, * as fromAvailability from './Availability';
import booking, * as fromBooking from './Booking';
import bookingWidget, * as fromBookingWidget from './BookingWidget';
import ui, * as fromUI from './UI';


const rootReducer = combineReducers({
  availability,
  booking,
  bookingWidget,
  ui,
});

export default persistReducer({
  key: 'root',
  storage,
  blacklist: ['ui'],
  transforms: [
    expireReducer('availability', {
      ...expireTransformBaseConfig,
      expiredState: fromAvailability.initialState,
    }),
    expireReducer('booking', {
      ...expireTransformBaseConfig,
      expiredState: fromBooking.initialState,
    }),
    expireReducer('bookingWidget', {
      ...expireTransformBaseConfig,
      expiredState: fromBookingWidget.initialState,
    }),
  ],
}, rootReducer);

export const getAvailableServices = (state) => fromAvailability.getAvailableServices(state.availability);
export const getAvailableStays = (state) => fromAvailability.getAvailableStays(state.availability);
export const getMaxOccupancy = (state, roomType) => fromAvailability.getMaxOccupancy(state.availability, roomType);

export const getAdditionalRooms = (state) => fromBooking.getAdditionalRooms(state.booking);
export const getBookingDetails = (state) => fromBooking.getBookingDetails(state.booking);
export const getReservation = (state) => fromBooking.getReservation(state.booking);
export const getRetrievedReservation = (state) => fromBooking.getRetrievedReservation(state.booking);
export const getServicesList = (state) => fromBooking.getServicesList(state.booking);
export const getStay = (state) => fromBooking.getStay(state.booking);
export const isFindBookingInProgress = (state) => fromBooking.isFindBookingInProgress(state.booking);
export const isRoomRequestInProgress = (state) => fromBooking.isRoomRequestInProgress(state.booking);

export const getSearchParams = (state) => fromBookingWidget.getSearchParams(state.bookingWidget);

export const getError = (state) => fromUI.getError(state.ui);
export const getNavOpen = (state) => fromUI.getNavOpen(state.ui);
export const getRetryAction = (state) => fromUI.getRetryAction(state.ui);
export const getWindowSize = (state) => fromUI.getWindowSize(state.ui);
