import {
  BOOKING_WIDGET_CLEAR_DATES,
  BOOKING_WIDGET_UPDATE_PARAMS,
  BOOKING_WIDGET_UPDATE_PROMO_CODE,
} from '../actionTypes';


export const initialState = {
  arrivalDate: null,
  departureDate: null,
  numAdults: 1,
  numChildren: 0,
  promoCode: '',
};

export default function bookingWidget(state = initialState, action) {
  switch (action.type) {
    case BOOKING_WIDGET_CLEAR_DATES:
      return {
        ...state,
        arrivalDate: null,
        departureDate: null,
      };
    case BOOKING_WIDGET_UPDATE_PARAMS:
      return {
        ...state,
        arrivalDate: action.payload.arrivalDate,
        departureDate: action.payload.departureDate,
        numAdults: action.payload.numAdults,
        numChildren: action.payload.numChildren,
      };
    case BOOKING_WIDGET_UPDATE_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload.promoCode,
      };
    default:
      return state;
  }
}

export const getSearchParams = (state) => state;
