import { generateFontSizes } from '../helpers/mixins';


const fontFallbackStack = 'Helvetica, Arial, sans-serif';

export const fonts = {
  fontFallbackStack,
  fontFamily: `Untitled, ${fontFallbackStack}`,
  fontFamilyItalic: `Untitled Italic, ${fontFallbackStack}`,
};

export const typeSizes = {
  xxl: generateFontSizes(60, 104, 120, 200),
  xl: generateFontSizes(40, 52, 60, 72),
  l: generateFontSizes(26, 38, 40, 52),
  m: generateFontSizes(16, 24, 24, 34),
  sm: generateFontSizes(16, 22, 16, 28),
  s: generateFontSizes(13, 20, 16, 28),
  xs: generateFontSizes(13, 20, 13, 20),
};
