const localeRegexp = /^\/([a-z]{2})\//;

export const isMailtoURI = (uri) => uri.match(/^mailto:/);

export const isTelURI = (uri) => uri.match(/^tel:/);

export const isMediaURI = (uri) => isMailtoURI(uri) || isTelURI(uri);

export const isInternalURI = (uri) => uri.match(/^\//);

export const createLocalisedURI = ({ locale, to }) => (`/${locale}${to}`);

export const getLocaleFromURI = (uri, fallback = 'en') => {
  let locale = fallback;
  const match = uri.match(localeRegexp);
  if (match) locale = match[1];
  return locale;
};

export const getURIWithoutLocale = (uri) => uri.replace(localeRegexp, '/');

export function isIntroPage(location) {
  if (!location || !location.pathname) {
    throw new Error('Invalid location', location);
  }

  return getURIWithoutLocale(location.pathname) === '/';
}

export const removeTrailingSlash = (uri) => (uri.length > 1 && uri[uri.length - 1] === '/' ? uri.substr(0, uri.length - 1) : uri);

export function parseURL(href) {
  const match = href.match(/^(https?|tel|mailto):(?:\/\/)?([^:/?#]*)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/i);

  if (match) {
    return {
      href: match[0],
      protocol: match[1],
      hostname: match[2],
      pathname: match[3],
      querystr: match[4],
      hash: match[5],
    };
  }
}
