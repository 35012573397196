import Cookie from 'js-cookie';

import { getCookieConsent } from './cookies';

// Delete Meta cookies

function fbqDeleteCookies() {
  Cookie.remove('_fbp');
}

// Page Tracking

export function fbqTrackPageview() {
  setTimeout(() => {
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  }, 500);
}

// Handle Tracking Consent

export function fbqHandleConsent() {
  if (typeof window === 'undefined') return;

  const { analytics, marketing } = getCookieConsent();
  const consent = analytics && marketing ? 'grant' : 'revoke';

  if (window.fbq) {
    // https://developers.facebook.com/docs/meta-pixel/implementation/gdpr/
    window.fbq('consent', consent);
  }

  if (consent === 'revoke') {
    fbqDeleteCookies();
  }
}

// Initialize Pixel tracking

export function fbqInit() {
  if (typeof window === 'undefined') return;

  fbqHandleConsent();

  if (window.fbq) {
    window.fbq('init', process.env.GATSBY_PUBLIC_FACEBOOK_PIXEL);
  }
}

// Ecommerce Tracking

export function fbqEcommerceConfirmation(addOns, reservation) {
  if (typeof window === 'undefined') return;

  let addOn;
  let total = 0;
  let count = 0;

  // Room Item

  const roomItem = {
    content_ids: [reservation.confirmationId],
    content_name: reservation.stay.roomTypeName,
    content_type: 'product',
    content_category: 'Room',
    value: reservation.stay.total / reservation.stay.duration,
    contents: [{
      id: reservation.confirmationId,
      quantity: reservation.stay.duration,
    }],
    currency: 'EUR',
  };

  total += reservation.stay.total;
  count += 1;

  if (window.fbq) {
    window.fbq('track', 'AddToCart', roomItem);
  }

  // AddOn Items

  addOns.forEach((a) => {
    addOn = {
      content_ids: [reservation.confirmationId],
      content_name: a.name,
      content_category: 'Add On',
      content_type: 'product',
      value: a.total / a.quantity,
      contents: [{
        id: reservation.confirmationId,
        quantity: a.quantity,
      }],
      currency: 'EUR',
    };

    total += a.total;
    count += 1;

    if (window.fbq) {
      window.fbq('track', 'AddToCart', addOn);
    }
  });

  // Purchase Object

  const purchase = {
    content_ids: [reservation.confirmationId],
    value: total,
    num_items: count,
    currency: 'EUR',
  };

  if (window.fbq) {
    window.fbq('track', 'Purchase', purchase);
  }
}
