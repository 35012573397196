export const API_ORIGIN = 'https://bookpoint.navarinoservices.com';

export const VALUE_CC_GUARANTEE = 'GTD';
export const VALUE_FLEX = 'FLX';
export const VALUE_FULL_PAYMENT = 'FUL';
export const VALUE_PREPAY = 'Prepay';
export const VALUE_ONE_NIGHT_PREPAY = '1nightPP';

export const GUARANTEE_CODES = [VALUE_CC_GUARANTEE, VALUE_FLEX];
export const PAYMENT_CODES = [VALUE_FULL_PAYMENT, VALUE_PREPAY, VALUE_ONE_NIGHT_PREPAY];

export const VALUE_HOTEL_CLOSED = 'Close';

export const VALUE_BREAKFAST = 'BFST2014';
export const VALUE_PARKING = 'PKL';
export const VALUE_HIDEOUT = 'HIDEOUT';
export const VALUE_LIFTOFF_BREAKFAST = 'LiftOff_BFST';
export const VALUE_LIFTOFF_LUNCH = 'LiftOff_Lunc';
export const VALUE_LIFTOFF_DINNER = 'LiftOff_Din';
export const VALUE_LIFTOFF_LEARN = 'LEARN';
export const VALUE_LD_BREAKFAST = 'LD_BFST'; // added by BaW (MH-10)
export const VALUE_LD_DINNER = 'LD_Dinner'; // added by BaW (MH-10)

export const VALUE_ID_CONFIRMATION = '14';
export const VALUE_ID_CANCELLATION = '15';

export const VALUE_MIN_AGE_ADULT = 10;

export const SERVICE_WHITELIST = [
  VALUE_BREAKFAST,
  VALUE_PARKING,
  VALUE_LIFTOFF_BREAKFAST,
  VALUE_LIFTOFF_LUNCH,
  VALUE_LIFTOFF_DINNER,
  VALUE_LIFTOFF_LEARN,
  VALUE_LD_DINNER, // added by BaW (MH-10)
  VALUE_LD_BREAKFAST, // added by BaW (MH-10)
];
