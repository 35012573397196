import { REM_SIZE } from './sizes';

// Breakpoints are expressed in ems (px/1rem)
export const viewportWidth = {
  xs: 375 / REM_SIZE,
  s: 675 / REM_SIZE,
  m: 800 / REM_SIZE,
  l: 1024 / REM_SIZE,
  xl: 1440 / REM_SIZE,
};

export const breakpoints = {
  xs: `(min-width: ${viewportWidth.xs}em)`,
  s: `(min-width: ${viewportWidth.s}em)`,
  m: `(min-width: ${viewportWidth.m}em)`,
  l: `(min-width: ${viewportWidth.l}em)`,
  xl: `(min-width: ${viewportWidth.xl}em)`,
};
