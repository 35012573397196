import camelcaseKeys from 'camelcase-keys';
import moment from 'moment';

import { call, put } from 'redux-saga/effects';

import api from '../../api/navarino';
import {
  SERVICE_WHITELIST, VALUE_HOTEL_CLOSED, VALUE_ID_CONFIRMATION, VALUE_ID_CANCELLATION,
} from '../../constants/api';
import { trackReservationPurchase, trackException } from '../../helpers/ga';

import {
  ADD_ROOM_ALLOWED,
  ADD_ROOM_DENIED,
  CANCEL_BOOKING_FAILED,
  CANCEL_BOOKING_SUCCEEDED,
  FETCH_AVAILABILITY_FAILED,
  FETCH_AVAILABILITY_SUCCEEDED,
  FETCH_ADDITIONAL_AVAILABILITY_FAILED,
  FETCH_ADDITIONAL_AVAILABILITY_SUCCEEDED,
  FETCH_BOOKING_FAILED,
  FETCH_BOOKING_NOT_FOUND,
  FETCH_BOOKING_SUCCEEDED,
  FETCH_GENERAL_AVAILABILITY_FAILED,
  FETCH_GENERAL_AVAILABILITY_SUCCEEDED,
  FETCH_INFO_FAILED,
  FETCH_INFO_SUCCEEDED,
  FETCH_SERVICES_FAILED,
  FETCH_SERVICES_SUCCEEDED,
  MAKE_BOOKING_FAILED,
  MAKE_BOOKING_SUCCEEDED,
} from '../actionTypes';
import { fbqEcommerceConfirmation } from '../../helpers/meta';


function groupServicesByCode(services) {
  const groupedServices = services
    .filter((s) => SERVICE_WHITELIST.includes(s.serviceInventoryCode))
    .reduce((acc, s) => {
      if (!acc[s.serviceInventoryCode]) {
        acc[s.serviceInventoryCode] = s;
      } else if (s.serviceDetails?.timeSpan) {
        const { end, start } = s.serviceDetails.timeSpan;
        const current = acc[s.serviceInventoryCode];
        if (start < current.serviceDetails.timeSpan.start) {
          acc[s.serviceInventoryCode].serviceDetails.timeSpan.start = start;
        }
        if (end > current.serviceDetails.timeSpan.end) {
          acc[s.serviceInventoryCode].serviceDetails.timeSpan.end = end;
        }
      }
      return acc;
    }, {});
  return Object.values(groupedServices);
}

function groupServicesByCodeUsingAttributes(services) {
  const groupedServices = services
    .reduce((acc, s) => {
      if (!acc[s.attributes.serviceInventoryCode]) {
        acc[s.attributes.serviceInventoryCode] = s;
      } else if (s.serviceDetails?.timeSpan) {
        const { end, start } = s.serviceDetails.timeSpan.attributes;
        const current = acc[s.attributes.serviceInventoryCode];
        if (start < current.serviceDetails.timeSpan.attributes.start) {
          acc[s.attributes.serviceInventoryCode].serviceDetails.timeSpan.attributes.start = start;
        }
        if (end > current.serviceDetails.timeSpan.attributes.end) {
          acc[s.attributes.serviceInventoryCode].serviceDetails.timeSpan.attributes.end = end;
        }
      }
      return acc;
    }, {});
  return Object.values(groupedServices);
}

function parseBooking(booking) {
  const roomStay = booking.roomStays?.roomStay[0];

  const { hotelCode } = roomStay.basicPropertyInfo;
  const { start, end } = roomStay.timeSpan;
  const { guestCount } = roomStay.guestCounts;
  const { numberOfUnits, ratePlanCode, roomTypeCode } = roomStay.roomRates.roomRate[0];
  const { amountAfterTax, currencyCode } = roomStay.total;

  const guestNums = guestCount.sort((a, b) => b.ageQualifyingCode - a.ageQualifyingCode);

  const ratePlan = roomStay.ratePlans.ratePlan[0];
  const { guarantee, cancelPenalties: { cancelPenalty } } = ratePlan;
  const { ratePlanName } = ratePlan;
  const ratePlanDescription = ratePlan.additionalDetails.additionalDetail[0].detailDescription.text;

  const roomType = roomStay.roomTypes.roomType[0];
  const roomTypeName = roomType.roomDescription.name;

  const stay = {
    rate: {
      plan: ratePlanCode,
      name: ratePlanName,
      desc: ratePlanDescription,
    },
    ratePlan: ratePlanCode,
    roomType: roomTypeCode,
    currency: currencyCode,
    total: amountAfterTax,
    arrivalDate: start,
    duration: moment(end).diff(moment(start), 'days'),
    adults: parseInt(guestNums[0].count, 10),
    children: guestNums.length > 1 ? parseInt(guestNums[1].count, 10) : 0,
    numRooms: parseInt(numberOfUnits, 10),
    roomTypeName,
    ratePlanName,
  };

  const { customer } = booking.resGuests?.resGuest[0].profiles?.profileInfo[0].profile;
  const guestDetails = {
    name: customer.personName[0].surname,
    telephone: customer.telephone[0].phoneNumber,
    email: customer.email[0],
    addressLines: customer.address[0].addressLine,
    addressCity: customer.address[0].cityName,
    postalCode: customer.address[0].postalCode,
  };

  const { cardNumber } = booking.resGlobalInfo?.guarantee?.guaranteesAccepted?.guaranteeAccepted[0].paymentCard;

  const services = booking.services?.service || [];

  const { hotelReservationId } = booking.resGlobalInfo?.hotelReservationIDs;
  const confirmationId = hotelReservationId.find((id) => id.resIdType === VALUE_ID_CONFIRMATION);
  const cancellationId = hotelReservationId.find((id) => id.resIdType === VALUE_ID_CANCELLATION);

  const cancelPolicy = {
    code: cancelPenalty[0].policyCode,
    text: cancelPenalty[0].penaltyDescription[0].text,
  };

  const guaranteePolicy = {
    code: guarantee.guaranteeCode,
    text: guarantee.guaranteeDescription.text,
  };

  return {
    confirmationId: confirmationId.resIdValue,
    cancellationId: cancellationId?.resIdValue,
    hotelCode,
    guestDetails,
    cancelPolicy,
    guaranteePolicy,
    services: groupServicesByCode(services),
    stay,
    creditCardSuffix: cardNumber ? cardNumber.substr(cardNumber.length - 4, 4) : null,
    isCancellable: booking.tpaExtensions.resStatus.isCancellable === 'true',
  };
}

function parseReservation(reservation) {
  const guest = reservation.guests[0].profiles.profileInfo['0'].profile.customer;
  const guestDetails = {
    name: guest.personName['0'].surname,
    telephone: guest.telephone['0'].phoneNumber,
    email: guest.email['0'],
    addressLines: Object.values(guest.address['0'].addressLine),
    addressCity: guest.address['0'].cityName,
    postalCode: guest.address['0'].postalCode,
  };

  const roomStay = reservation.stays[0];
  const cancelPolicy = {
    code: roomStay.cancelPenalties.cancelPenalty['0'].policyCode,
    text: roomStay.cancelPenalties.cancelPenalty['0'].penaltyDescription['0'].text,
  };
  const guaranteePolicy = {
    code: roomStay.guarantee.guaranteeCode,
    text: roomStay.guarantee.guaranteeDescription.text,
  };

  const { start, end } = roomStay.timeSpan;
  const { guestCount } = roomStay.guestCounts;
  const guestNums = Object.values(guestCount).sort((a, b) => parseInt(b.ageQualifyingCode, 10) - parseInt(a.ageQualifyingCode, 10));

  const { numberOfUnits, ratePlanCode, roomTypeCode } = roomStay.roomRates.roomRate[0];
  const ratePlan = roomStay.ratePlans.ratePlan[0];
  const { ratePlanName } = ratePlan;
  const ratePlanDescription = ratePlan.additionalDetails.additionalDetail[0].detailDescription.text;

  const roomType = roomStay.roomTypes.roomType[0];
  const roomTypeName = roomType.roomDescription.name;

  const stay = {
    rate: {
      plan: ratePlanCode,
      name: ratePlanName,
      desc: ratePlanDescription,
    },
    ratePlan: ratePlanCode,
    roomType: roomTypeCode,
    total: parseFloat(reservation.total),
    arrivalDate: start,
    duration: moment(end).diff(moment(start), 'days'),
    adults: parseInt(guestNums[0].count, 10),
    children: guestNums.length > 1 ? parseInt(guestNums[1].count, 10) : 0,
    numRooms: parseInt(numberOfUnits, 10),
    roomTypeName,
    ratePlanName,
  };

  const services = reservation.services || [];

  return {
    confirmationId: reservation.id,
    stay,
    services: groupServicesByCodeUsingAttributes(services),
    guestDetails,
    cancelPolicy,
    guaranteePolicy,
  };
}

function staysToObject(stays) {
  return stays.map((stay) => ([stay.roomType, stay.ratePlan, stay]))
    .reduce((collection, [roomType, ratePlan, stay]) => {
      if (collection[roomType]) {
        collection[roomType][ratePlan] = stay;
      } else {
        collection[roomType] = {
          [ratePlan]: stay,
        };
      }
      return collection;
    }, {});
}

function infoToMaxOccupancy(rooms) {
  return rooms
    .reduce((acc, r) => {
      acc[r.code] = parseInt(r.maxOccupancy, 10);
      return acc;
    }, {});
}

function availabilityToArray(stays) {
  return stays.filter((s) => s.status === VALUE_HOTEL_CLOSED)
    .map((s) => moment(s.arrivalDate));
}

const isPromoCodeValid = (promoCode, warnings) => (!!promoCode && (!warnings || !warnings.find((w) => w.includes(`Invalid Promotion Code: ${promoCode}`))));

export function* fetchGeneralAvailability(action) {
  try {
    const { data, error } = yield call(api.fetchGeneralAvailability, action.payload);
    if (data) {
      const result = camelcaseKeys(data, { deep: true });
      const blockedDays = result.hotelStays ? availabilityToArray(result.hotelStays) : [];
      yield put({ type: FETCH_GENERAL_AVAILABILITY_SUCCEEDED, blockedDays });
    } else {
      throw error;
    }
  } catch (error) {
    yield call(trackException, `bookpoint: ${error}`, true);
    yield put({ type: FETCH_GENERAL_AVAILABILITY_FAILED });
  }
}

export function* fetchHotelInfo() {
  try {
    const { data, error } = yield call(api.fetchHotelInfo);
    if (data) {
      const result = camelcaseKeys(data, { deep: true });
      const contents = result.hotelDescriptiveContent.length && result.hotelDescriptiveContent[0];
      const rooms = contents?.facilityInfo?.guestRooms?.guestRoom;
      const maxOccupancy = rooms ? infoToMaxOccupancy(rooms) : {};
      yield put({ type: FETCH_INFO_SUCCEEDED, maxOccupancy });
    } else {
      throw error;
    }
  } catch (error) {
    yield call(trackException, `bookpoint: ${error}`, true);
    yield put({ type: FETCH_INFO_FAILED });
  }
}

export function* fetchAdditionalAvailability(action) {
  try {
    const { data, error } = yield call(api.fetchAvailability, action.payload);
    if (data) {
      const result = camelcaseKeys(data, { deep: true });
      yield put({ type: FETCH_ADDITIONAL_AVAILABILITY_SUCCEEDED });
      const isAllowed = result.stays?.stay && result.stays.stay.length > 0;
      yield put({ type: isAllowed ? ADD_ROOM_ALLOWED : ADD_ROOM_DENIED });
    } else {
      throw error;
    }
  } catch (error) {
    yield call(trackException, `bookpoint: ${error}`, true);
    yield put({ type: FETCH_ADDITIONAL_AVAILABILITY_FAILED });
  }
}

export function* fetchAvailability(action) {
  const { promoCode } = action.payload;
  try {
    const { data, error } = yield call(api.fetchAvailability, action.payload);
    if (data) {
      const result = camelcaseKeys(data, { deep: true });
      const stays = result.stays?.stay ? staysToObject(result.stays.stay) : {};
      const effect = { type: FETCH_AVAILABILITY_SUCCEEDED, stays };
      if (promoCode) effect.promoCodeValid = isPromoCodeValid(promoCode, result.warnings);
      yield put(effect);
    } else {
      throw error;
    }
  } catch (error) {
    yield call(trackException, `bookpoint: ${error}`, true);
    yield put({ type: FETCH_AVAILABILITY_FAILED });
  }
}

export function* fetchServices(action) {
  try {
    const { data, error } = yield call(api.fetchServices, action.payload);
    if (data) {
      const result = camelcaseKeys(data, { deep: true });
      const services = result.services?.service || [];
      yield put({ type: FETCH_SERVICES_SUCCEEDED, services: groupServicesByCode(services) });
    } else {
      throw error;
    }
  } catch (error) {
    yield call(trackException, `bookpoint: ${error}`, true);
    yield put({ type: FETCH_SERVICES_FAILED });
  }
}

export function* makeBookingRequest(action) {
  try {
    const { data, error } = yield call(api.makeBooking, action.payload);
    if (data && data.reservations) {
      const result = camelcaseKeys(data, { deep: true });
      const reservation = parseReservation(result.reservations[0]);
      yield call(trackReservationPurchase, action.payload.services, reservation);
      yield call(fbqEcommerceConfirmation, action.payload.services, reservation);
      yield put({ type: MAKE_BOOKING_SUCCEEDED, reservation });
    } else {
      throw error;
    }
  } catch (error) {
    yield call(trackException, `bookpoint: ${error}`, true);
    yield put({ type: MAKE_BOOKING_FAILED });
  }
}

export function* fetchBooking(action) {
  try {
    const { email } = action.payload;
    const { data, error } = yield call(api.fetchBooking, action.payload);
    if (data) {
      const result = camelcaseKeys(data, { deep: true });
      const booking = result.hotelReservations?.hotelReservation[0];
      const reservation = booking ? parseBooking(booking) : null;
      if (reservation && reservation.guestDetails.email.toLowerCase() === email.trim().toLowerCase()) {
        yield put({ type: FETCH_BOOKING_SUCCEEDED, reservation });
      } else {
        yield put({ type: FETCH_BOOKING_NOT_FOUND });
      }
    } else {
      throw error;
    }
  } catch (error) {
    yield call(trackException, `bookpoint: ${error}`, true);
    yield put({ type: FETCH_BOOKING_FAILED });
  }
}

export function* cancelBooking(action) {
  try {
    const { data, error } = yield call(api.cancelBooking, action.payload);
    if (data) {
      const result = camelcaseKeys(data, { deep: true });
      const { cancelInfoRs: { uniqueId: { id } } } = result;
      yield put({ type: CANCEL_BOOKING_SUCCEEDED, payload: { confirmationId: id } });
    } else {
      throw error;
    }
  } catch (error) {
    yield call(trackException, `bookpoint: ${error}`, true);
    yield put({ type: CANCEL_BOOKING_FAILED });
  }
}
