// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-cafe-bar-js": () => import("./../../../src/templates/cafeBar.js" /* webpackChunkName: "component---src-templates-cafe-bar-js" */),
  "component---src-templates-event-room-js": () => import("./../../../src/templates/eventRoom.js" /* webpackChunkName: "component---src-templates-event-room-js" */),
  "component---src-templates-event-rooms-js": () => import("./../../../src/templates/eventRooms.js" /* webpackChunkName: "component---src-templates-event-rooms-js" */),
  "component---src-templates-family-js": () => import("./../../../src/templates/family.js" /* webpackChunkName: "component---src-templates-family-js" */),
  "component---src-templates-farm-js": () => import("./../../../src/templates/farm.js" /* webpackChunkName: "component---src-templates-farm-js" */),
  "component---src-templates-find-booking-js": () => import("./../../../src/templates/findBooking.js" /* webpackChunkName: "component---src-templates-find-booking-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/genericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-menu-js": () => import("./../../../src/templates/menu.js" /* webpackChunkName: "component---src-templates-menu-js" */),
  "component---src-templates-restaurant-js": () => import("./../../../src/templates/restaurant.js" /* webpackChunkName: "component---src-templates-restaurant-js" */),
  "component---src-templates-room-js": () => import("./../../../src/templates/room.js" /* webpackChunkName: "component---src-templates-room-js" */),
  "component---src-templates-rooms-js": () => import("./../../../src/templates/rooms.js" /* webpackChunkName: "component---src-templates-rooms-js" */),
  "component---src-templates-shared-places-js": () => import("./../../../src/templates/sharedPlaces.js" /* webpackChunkName: "component---src-templates-shared-places-js" */)
}

