/* eslint-disable no-continue, no-console */
import moment from 'moment';
import { navigate } from 'gatsby';
import { delay } from 'redux-saga';

import {
  call, fork, put, select, take, takeLatest,
} from 'redux-saga/effects';

import {
  BOOKING_ADDONS_PAGE,
  BOOKING_ADD_ROOM_PAGE,
  BOOKING_DETAILS_PAGE,
  BOOKING_CONFIRMATION_PAGE,
  BOOKING_FIND_PAGE,
  BOOKING_RESERVATION_PAGE,
} from '../../constants/locations';
import {
  createLocalisedURI, getLocaleFromURI, getURIWithoutLocale, removeTrailingSlash,
} from '../../helpers/location';
import {
  ADD_ROOM_ALLOWED,
  ADD_ROOM_DENIED,
  ADD_ROOM_REQUESTED,
  ADD_SERVICE,
  BOOKING_ADD_ROOM_REQUESTED,
  BOOKING_ADD_ROOM_SUCCEEDED,
  BOOKING_ADD_SERVICE,
  BOOKING_REMOVE_SERVICE,
  BOOKING_SELECT_SERVICES,
  BOOKING_SUBMIT_DETAILS_FORM,
  BOOKING_SUBMIT_SEARCH_FORM,
  BOOKING_UPDATE_STAY,
  CANCEL_BOOKING_FAILED,
  CANCEL_BOOKING_REQUESTED,
  CANCEL_BOOKING_SUCCEEDED,
  FETCH_ADDITIONAL_AVAILABILITY_FAILED,
  FETCH_AVAILABILITY_FAILED,
  FETCH_AVAILABILITY_REQUESTED,
  FETCH_AVAILABILITY_RETRIED,
  FETCH_AVAILABILITY_SUCCEEDED,
  FETCH_BOOKING_FAILED,
  FETCH_BOOKING_NOT_FOUND,
  FETCH_BOOKING_REQUESTED,
  FETCH_BOOKING_SUCCEEDED,
  FETCH_GENERAL_AVAILABILITY_FAILED,
  FETCH_GENERAL_AVAILABILITY_SUCCEEDED,
  FETCH_INFO_FAILED,
  FETCH_INFO_SUCCEEDED,
  FETCH_SERVICES_FAILED,
  FETCH_SERVICES_SUCCEEDED,
  FETCH_SERVICES_RETRIED,
  MAKE_BOOKING_FAILED,
  MAKE_BOOKING_REQUESTED,
  MAKE_BOOKING_SUCCEEDED,
  REMOVE_SERVICE,
  ROUTER_LOCATION_CHANGED,
  SEARCH_PARAMS_UPDATED,
  SELECT_SERVICES,
  SELECT_STAY,
  SHOW_ERROR,
  UNSET_ERROR,
} from '../actionTypes';
import {
  getAdditionalRooms, getAvailableStays, getMaxOccupancy, getRetrievedReservation,
  getStay, getSearchParams, getServicesList,
} from '../reducers';

import {
  cancelBooking, fetchAdditionalAvailability, fetchAvailability, fetchBooking,
  fetchGeneralAvailability, fetchHotelInfo, fetchServices, makeBookingRequest,
} from './api';


const RETRY_ATTEMPTS = 5;
const RETRY_DELAY = 2000;

export function* calendarSaga() {
  for (let i = 0; i < RETRY_ATTEMPTS; i += 1) {
    yield fork(fetchGeneralAvailability, { payload: { numDays: 60 } });
    const result = yield take([FETCH_GENERAL_AVAILABILITY_SUCCEEDED, FETCH_GENERAL_AVAILABILITY_FAILED]);
    if (result.type === FETCH_GENERAL_AVAILABILITY_FAILED) {
      yield delay(RETRY_DELAY);
    } else {
      break;
    }
  }
}

export function* infoSaga() {
  for (let i = 0; i < RETRY_ATTEMPTS; i += 1) {
    yield fork(fetchHotelInfo);
    const result = yield take([FETCH_INFO_SUCCEEDED, FETCH_INFO_FAILED]);
    if (result.type === FETCH_INFO_FAILED) {
      yield delay(RETRY_DELAY);
    } else {
      break;
    }
  }
}

function createFetchServicesPayload(stay, locale) {
  const { adults, arrivalDate, children, duration, ratePlan, roomType } = stay;
  return {
    arrivalDate,
    duration,
    language: locale,
    numAdults: adults,
    numChildren: children,
    ratePlan,
    roomType,
  };
}

function* fetchServicesSaga(stay, locale) {
  const payload = createFetchServicesPayload(stay, locale);

  let result;
  while (true) {
    yield fork(fetchServices, { payload });
    result = yield take([FETCH_SERVICES_SUCCEEDED, FETCH_SERVICES_FAILED]);
    if (result.type === FETCH_SERVICES_FAILED) {
      yield put({ type: SHOW_ERROR, error: { key: 'global.genericErrorWithRetry' }, action: FETCH_SERVICES_RETRIED });
      yield take(FETCH_SERVICES_RETRIED);
      continue;
    } else {
      yield put({ type: UNSET_ERROR });
      break;
    }
  }

  const { services } = result;
  return services;
}

export function* searchSaga(action) {
  const { locale } = action.payload;

  // Get the action payload from state
  const searchParams = yield select(getSearchParams);
  if (!searchParams.arrivalDate || !searchParams.departureDate) {
    return;
  }

  yield put({ type: FETCH_AVAILABILITY_REQUESTED });
  const payload = { ...searchParams, language: locale };

  // Wait for either failure or success
  while (true) {
    yield fork(fetchAvailability, { payload });
    const result = yield take([FETCH_AVAILABILITY_SUCCEEDED, FETCH_AVAILABILITY_FAILED]);
    if (result.type === FETCH_AVAILABILITY_FAILED) {
      yield put({ type: SHOW_ERROR, error: { key: 'global.genericErrorWithRetry' }, action: FETCH_AVAILABILITY_RETRIED });
      yield take(FETCH_AVAILABILITY_RETRIED);
      continue;
    } else {
      yield put({ type: UNSET_ERROR });
      break;
    }
  }
}

function* staySaga(action) {
  const { payload: { language, roomType, ratePlan } } = action;
  const stays = yield select(getAvailableStays);
  const stay = stays[roomType][ratePlan];
  yield put({ type: BOOKING_UPDATE_STAY, stay });

  const services = yield call(fetchServicesSaga, stay, language);
  const next = createLocalisedURI({
    locale: language,
    to: services.length ? BOOKING_ADDONS_PAGE : BOOKING_DETAILS_PAGE,
  });
  navigate(next);
}

export function* fetchAdditionalAvailabilitySaga(locale) {
  const { adults, arrivalDate, children, duration, numRooms, ratePlan, roomType } = yield select(getStay);
  const payload = {
    arrivalDate,
    departureDate: moment(arrivalDate).add(duration, 'days'),
    language: locale,
    numAdults: adults,
    numChildren: children,
    numRooms: numRooms + 1,
    ratePlan,
    roomType,
  };

  yield fork(fetchAdditionalAvailability, { payload });
  const action = yield take([ADD_ROOM_ALLOWED, ADD_ROOM_DENIED]);
  if (action.type === ADD_ROOM_ALLOWED) {
    const next = createLocalisedURI({
      locale,
      to: BOOKING_ADD_ROOM_PAGE,
    });
    navigate(next, { state: { roomId: numRooms + 1 } });
  }
}

export function* addRoomSaga(locale) {
  let payload;
  let stay;
  while (true) {
    const action = yield take([REMOVE_SERVICE, BOOKING_ADD_ROOM_REQUESTED]);
    if (action.type === REMOVE_SERVICE) {
      yield put({ type: BOOKING_REMOVE_SERVICE, payload: action.payload, roomId: action.roomId });
    } else {
      payload = action.payload;
      const total = payload.numAdults + payload.numChildren;
      stay = yield select(getStay);
      const maxOccupancy = yield select(getMaxOccupancy, stay.roomType);
      if (!maxOccupancy || total > maxOccupancy || total < 1 || payload.numAdults < 1) {
        yield put({ type: SHOW_ERROR, error: { key: 'global.genericErrorWithRetry' } });
      } else {
        yield put({ type: UNSET_ERROR });
        break;
      }
    }
  }
  yield put({ type: BOOKING_ADD_ROOM_SUCCEEDED, payload });
  const roomStay = { ...stay, adults: payload.numAdults, children: payload.numChildren };
  const services = yield call(fetchServicesSaga, roomStay, locale);
  const next = createLocalisedURI({
    locale,
    to: services.length ? BOOKING_ADDONS_PAGE : BOOKING_DETAILS_PAGE,
  });
  navigate(next, { state: { roomId: payload.roomId } });
}

export function* addOnsSaga(locale) {
  while (true) {
    const action = yield take([ADD_SERVICE, REMOVE_SERVICE, SELECT_SERVICES, ADD_ROOM_REQUESTED]);
    if (action.type === ADD_SERVICE) {
      yield put({ type: BOOKING_ADD_SERVICE, payload: action.payload, roomId: action.roomId });
    } else if (action.type === REMOVE_SERVICE) {
      yield put({ type: BOOKING_REMOVE_SERVICE, payload: action.payload, roomId: action.roomId });
    } else if (action.type === SELECT_SERVICES) {
      yield put({ type: BOOKING_SELECT_SERVICES });
      yield put({ type: UNSET_ERROR });
      break;
    } else {
      yield fork(fetchAdditionalAvailabilitySaga, locale);
      const result = yield take([ADD_ROOM_ALLOWED, ADD_ROOM_DENIED, FETCH_ADDITIONAL_AVAILABILITY_FAILED]);
      if (result.type === FETCH_ADDITIONAL_AVAILABILITY_FAILED) {
        yield put({ type: SHOW_ERROR, error: { key: 'global.genericErrorWithRetry' }, action: ADD_ROOM_REQUESTED });
        continue;
      }
      if (result.type === ADD_ROOM_ALLOWED) {
        yield put({ type: UNSET_ERROR });
        return;
      }
    }
  }
  const next = createLocalisedURI({
    locale,
    to: BOOKING_DETAILS_PAGE,
  });
  navigate(next);
}

// eslint is disabled on this whole function because somehow disabling
// no-labels and no-restricted syntax is not enough: although the warnings go
// away, the autofix still removes the `sagaLoop` labels which are required for
// this function to work properly.
/* eslint-disable */
export function* detailsSaga(locale) {
  sagaLoop: while (true) {
    const action = yield take([REMOVE_SERVICE, ADD_ROOM_REQUESTED, BOOKING_SUBMIT_DETAILS_FORM]);
    if (action.type === REMOVE_SERVICE) {
      yield put({ type: BOOKING_REMOVE_SERVICE, payload: action.payload, roomId: action.roomId });
    } else if (action.type === ADD_ROOM_REQUESTED) {
      yield fork(fetchAdditionalAvailabilitySaga, locale);
      const result = yield take([ADD_ROOM_ALLOWED, ADD_ROOM_DENIED, FETCH_ADDITIONAL_AVAILABILITY_FAILED]);
      if (result.type === FETCH_ADDITIONAL_AVAILABILITY_FAILED) {
        yield put({ type: SHOW_ERROR, error: { key: 'global.genericErrorWithRetry' }, action: ADD_ROOM_REQUESTED });
        continue sagaLoop;
      }
      if (result.type === ADD_ROOM_ALLOWED) {
        yield put({ type: UNSET_ERROR });
        return;
      }
    } else {
      yield put({ type: UNSET_ERROR });
      const details = action.payload;

      // This is a bit weird but makes testing much easier
      const limitedDetails = {};
      if (Object.keys(details).length) {
        limitedDetails.fullName = details.fullName;
        limitedDetails.phone = details.phone;
        limitedDetails.email = details.email;
        limitedDetails.companyName = details.companyName;
        limitedDetails.isBusinessStay = !!details.isBusinessStay;
        limitedDetails.addressLine1 = details.addressLine1;
        limitedDetails.addressLine2 = details.addressLine2;
        limitedDetails.city = details.city;
        limitedDetails.postalCode = details.postalCode;
        limitedDetails.country = details.country;
        limitedDetails.specialReqs = details.specialReqs;
        limitedDetails.arrivalTime = details.arrivalTime;
        limitedDetails.creditCardNumber = details.creditCardNumber;
        limitedDetails.creditCardExpiryMonth = details.creditCardExpiryMonth;
        limitedDetails.creditCardExpiryYear = details.creditCardExpiryYear;
        limitedDetails.creditCardName = details.creditCardName;
        limitedDetails.privacyPolicy = !!details.privacyPolicy;
        limitedDetails.termsConditions = !!details.termsConditions;

        const trimmedCCNumber = details.creditCardNumber.replace(/\s/g, '');
        limitedDetails.creditCardSuffix = trimmedCCNumber.substr(trimmedCCNumber.length - 4, 4);
      }
      yield put({ type: MAKE_BOOKING_REQUESTED, details: limitedDetails });

      const searchParams = yield select(getSearchParams);
      const stay = yield select(getStay);
      let services = yield select(getServicesList);

      let totalAdults = stay.adults;
      let totalChildren = stay.children;

      const additionalRooms = yield select(getAdditionalRooms);
      const additionalRoomsList = Object.values(additionalRooms);
      if (additionalRoomsList.length) {
        totalAdults = additionalRoomsList.reduce((acc, r) => acc + r.adults, totalAdults);
        totalChildren = additionalRoomsList.reduce((acc, r) => acc + r.children, totalChildren);
        additionalRoomsList.forEach((r) => {
          const additionalServices = Object.values(r.services);
          services = services.concat(additionalServices);
        });
      }

      const payload = {
        language: locale,
        details,
        services,
        stay: {
          ...stay,
          adults: totalAdults,
          children: totalChildren,
          promoCode: searchParams?.promoCode,
        },
      };

      yield fork(makeBookingRequest, { payload });
      const result = yield take([MAKE_BOOKING_SUCCEEDED, MAKE_BOOKING_FAILED]);
      if (result.type === MAKE_BOOKING_FAILED) {
        yield put({ type: SHOW_ERROR, error: { key: 'global.genericErrorWithRetry'} });
        continue sagaLoop;
      } else {
        yield put({ type: UNSET_ERROR });
        break sagaLoop;
      }
    }
  }

  const next = createLocalisedURI({
    locale,
    to: BOOKING_CONFIRMATION_PAGE,
  });
  navigate(next);
}
/* eslint-enable */

export function* findBookingSaga(locale) {
  while (true) {
    const { payload } = yield take(BOOKING_SUBMIT_SEARCH_FORM);
    yield put({ type: FETCH_BOOKING_REQUESTED });
    yield fork(fetchBooking, { payload });

    const result = yield take([FETCH_BOOKING_NOT_FOUND, FETCH_BOOKING_SUCCEEDED, FETCH_BOOKING_FAILED]);
    if (result.type === FETCH_BOOKING_NOT_FOUND) {
      yield put({ type: SHOW_ERROR, error: { key: 'booking.noReservationFound' } });
    } else if (result.type === FETCH_BOOKING_FAILED) {
      yield put({ type: SHOW_ERROR, error: { key: 'global.genericErrorWithRetry' } });
    } else {
      yield put({ type: UNSET_ERROR });
      break;
    }
  }

  const next = createLocalisedURI({
    locale,
    to: BOOKING_RESERVATION_PAGE,
  });
  navigate(next);
}

export function* cancelBookingSaga() {
  yield take(CANCEL_BOOKING_REQUESTED);
  const reservation = yield select(getRetrievedReservation);
  const { confirmationId, hotelCode } = reservation;
  yield call(cancelBooking, { payload: { confirmationNumber: confirmationId, hotelCode } });
  yield take([CANCEL_BOOKING_SUCCEEDED, CANCEL_BOOKING_FAILED]);
}

const SAGA_FOR_ROUTE = {
  [BOOKING_ADDONS_PAGE]: addOnsSaga,
  [BOOKING_ADD_ROOM_PAGE]: addRoomSaga,
  [BOOKING_DETAILS_PAGE]: detailsSaga,
  [BOOKING_FIND_PAGE]: findBookingSaga,
  [BOOKING_RESERVATION_PAGE]: cancelBookingSaga,
};

export function* routeSaga(action) {
  const { location } = action.payload;

  const locale = getLocaleFromURI(location);

  const saga = SAGA_FOR_ROUTE[removeTrailingSlash(getURIWithoutLocale(location))];
  if (saga) {
    yield call(saga, locale);
  }
}

export default function* bookingSaga() {
  yield fork(calendarSaga);
  yield fork(infoSaga);
  yield takeLatest(ROUTER_LOCATION_CHANGED, routeSaga);
  yield takeLatest(SEARCH_PARAMS_UPDATED, searchSaga);
  yield takeLatest(SELECT_STAY, staySaga);
}
