module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/AppLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://26579ec457584d21aa3b4c8034502a8b@sentry.io/1207530"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
