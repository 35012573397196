import {
  BOOKING_WIDGET_CLEAR_DATES,
  FETCH_AVAILABILITY_FAILED,
  FETCH_AVAILABILITY_REQUESTED,
  FETCH_AVAILABILITY_SUCCEEDED,
  FETCH_GENERAL_AVAILABILITY_SUCCEEDED,
  FETCH_INFO_SUCCEEDED,
  FETCH_SERVICES_SUCCEEDED,
} from '../actionTypes';


export const initialState = {
  blockedDays: [],
  maxOccupancy: {},
  promoCodeValid: false,
  services: [],
  stays: {},
  searchInitiated: false,
  searchInProgress: false,
};

export default function availability(state = initialState, action) {
  switch (action.type) {
    case BOOKING_WIDGET_CLEAR_DATES:
      return {
        ...state,
        stays: {},
        searchInitiated: false,
        searchInProgress: false,
        services: [],
      };
    case FETCH_INFO_SUCCEEDED:
      return {
        ...state,
        maxOccupancy: action.maxOccupancy,
      };
    case FETCH_GENERAL_AVAILABILITY_SUCCEEDED:
      return {
        ...state,
        blockedDays: action.blockedDays,
      };
    case FETCH_AVAILABILITY_REQUESTED:
      return {
        ...state,
        searchInitiated: true,
        searchInProgress: true,
      };
    case FETCH_AVAILABILITY_SUCCEEDED:
      return {
        ...state,
        promoCodeValid: !!action.promoCodeValid,
        searchInProgress: false,
        stays: action.stays,
      };
    case FETCH_AVAILABILITY_FAILED:
      return {
        ...state,
        searchInProgress: false,
      };
    case FETCH_SERVICES_SUCCEEDED:
      return {
        ...state,
        services: action.services,
      };
    default:
      return state;
  }
}

export const getAvailableServices = (state) => state.services;
export const getAvailableStays = (state) => state.stays;
export const getMaxOccupancy = (state, roomType) => state.maxOccupancy[roomType];
