export const BOOKING_PAGES = '/book';
export const BOOKING_ADDONS_PAGE = `${BOOKING_PAGES}/extras`;
export const BOOKING_ADD_ROOM_PAGE = `${BOOKING_PAGES}/addroom`;
export const BOOKING_DETAILS_PAGE = `${BOOKING_PAGES}/details`;
export const BOOKING_CONFIRMATION_PAGE = `${BOOKING_PAGES}/confirmation`;
export const BOOKING_RESERVATION_PAGE = `${BOOKING_PAGES}/reservation`;

// This doesn't have the `book` prefix because it doesn't rely on having a stay
// in the store and can therefore be server-side rendered, unlike the others.
export const BOOKING_FIND_PAGE = '/booking';
