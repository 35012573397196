/**
 * Given an object, add a prefix to all keys.
 */
const namespaceKeys = (obj, prefix) => (
  Object.keys(obj)
    .reduce((nsObj, msgId) => {
      nsObj[`${prefix}.${msgId}`] = obj[msgId];
      return nsObj;
    }, {})
);

/**
 * Helper to get only the GraphQL node for the given locale.
 */
export const getSingletonForLang = (data, langKey) => (
  data.edges.filter((e) => e.node.locale === langKey)[0].node
);


/**
 * Generates an object where the keys are namespaced i18n message ids
 * and the values are the message strings for a given locale.
 */
export const getMessages = (data) => (
  Object.keys(data)
    .map((prefix) => {
      const clone = { ...data[prefix] };
      delete clone.locale;
      return namespaceKeys(clone, prefix);
    })
    .reduce((msgs, subset) => ({ ...msgs, ...subset }), {})
);

/**
 * Borrowed from
 * https://github.com/angeloocana/gatsby-plugin-i18n/blob/9c8044f7096e219830e4608d42be727be82eaa4a/packages/ptz-i18n/src/getBrowserLanguage.js
 */
export const getBrowserLanguage = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const first = window.navigator.languages
    ? window.navigator.languages[0]
    : null;

  const lang = first
    || window.navigator.language
    || window.navigator.browserLanguage
    || window.navigator.userLanguage;

  return lang;
};

/**
 * Based on
 * https://github.com/angeloocana/gatsby-plugin-i18n/blob/9c8044f7096e219830e4608d42be727be82eaa4a/packages/ptz-i18n/src/getValidLangKey.js
 */
export const getValidLangKey = (langs, defaultLangKey, langKey) => {
  if (!langKey) {
    return defaultLangKey;
  }

  const currentLangKey = langs.find((l) => l.startsWith(langKey));
  return currentLangKey || defaultLangKey;
};

/**
 * Based on
 * https://github.com/angeloocana/gatsby-plugin-i18n/blob/9c8044f7096e219830e4608d42be727be82eaa4a/packages/ptz-i18n/src/getUserLangKey.js
 */
export const getUserLangKey = (langs, defaultLangKey) => (getValidLangKey(langs, defaultLangKey, getBrowserLanguage()));

/**
 * Borrowed from
 * https://github.com/angeloocana/gatsby-plugin-i18n/blob/9c8044f7096e219830e4608d42be727be82eaa4a/packages/ptz-i18n/src/getCurrentLangKey.js
 */
export const getCurrentLangKey = (langs, defaultLangKey, url) => {
  const langKey = url.split('/')[1];
  return getValidLangKey(langs, defaultLangKey, langKey);
};

export const findValueForLocale = (allLocales, langKey) => {
  if (!langKey) {
    console.error('No langKey provided');
    return null;
  }
  if (!allLocales || !allLocales.length) return null;
  return allLocales.find((l) => l.locale === langKey)?.value || null;
};
