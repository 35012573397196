import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Container = styled.div`
  position: absolute;
  width: 100%;
`;

class TransitionHandler extends React.Component {
  shouldComponentUpdate() {
    return this.props.location.pathname === window.location.pathname;
  }

  render() {
    const { children } = this.props;
    return (
      <Container>
        {children}
      </Container>
    );
  }
}

TransitionHandler.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};

export default TransitionHandler;
