import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Helmet from 'react-helmet';
import { IntlProvider, addLocaleData } from 'react-intl';
import debounce from 'lodash.debounce';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';
import 'moment/locale/de';


import { breakpoints } from '../constants/mediaqueries';
import { fonts } from '../constants/typography';
import { canUseDOM } from '../helpers/dom';
import { getCurrentLangKey, getMessages, getSingletonForLang } from '../helpers/i18n';
import { WINDOW_RESIZE } from '../state/actionTypes';

import {
  TRANSITION_TIME_TOTAL,
  TRANSITION_TIME_ENTER,
  TRANSITION_TIME_LEAVE,
  TRANSITION_TIME_GRADIENT,
} from '../constants/transitions';

import '../css/reset.css';
import '../css/global.css';

import TransitionHandler from './TransitionHandler';


const OuterWrapper = styled.div`
  position: relative;
  line-height: 1.5;

  .gradient {
    will-change: opacity;
  }

  .page-transition-enter {
    z-index: 1;
    .content,
    .gradient {
      opacity: 0.01;
    }
  }

  .page-transition-enter.page-transition-enter-active {
    .content, .gradient {
      opacity: 1;
    }

    .gradient {
      transition: opacity ${TRANSITION_TIME_GRADIENT}ms linear;
    }

    .content {
      transform: translate3D(0, 0, 0);
      transition: opacity ${TRANSITION_TIME_ENTER}ms linear;
      transition-delay: ${TRANSITION_TIME_LEAVE}ms;
    }

    /* Don't animate the menu toggle or arrow */
    .arrow,
    > button,
    > div > span {
      opacity: 0;
    }
  }

  .page-transition-exit {
    .content, .gradient {
      opacity: 1;
    }

    /* Don't animate the menu toggle */
    > button,
    > div > span {
      opacity: 0;
    }
  }

  .page-transition-exit.page-transition-exit-active {
    .content {
      opacity: 0.01;
      transition: opacity ${TRANSITION_TIME_LEAVE}ms linear;
    }
  }

  strong, b {
    font-weight: 400;
  }

  em, i {
    font-style: italic;
  }

  .fonts-loaded em,
  .fonts-loaded i {
    font-family: ${fonts.fontFamilyItalic};
  }
`;


class AppLayout extends Component {
  constructor(props) {
    super(props);

    addLocaleData([...en, ...de]);

    this.debouncedResizeHandler = debounce(this.handleResize.bind(this));
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedResizeHandler);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResizeHandler);
  }

  handleResize() {
    let viewportSize = 'xxs';
    Object.entries(breakpoints).forEach(([size, mq]) => {
      if (window.matchMedia(mq).matches) {
        viewportSize = size;
      }
    });

    this.props.updateWindowSize(viewportSize);
  }

  render() {
    const { children, location } = this.props;

    return (
      <StaticQuery
        query={graphql`
          {
            site {
              siteMetadata {
                title
                siteUrl
                defaultLangKey
              }
            }
            datoCmsSite {
              locales
            }
            allDatoCmsAddOnsMessaging {
              edges {
                node {
                  locale
                  breadcrumbTitle
                  skipLink
                  pageTitle
                  pageTitleMultiRoom
                  numberOfAdults
                  costPerAdultShort
                  numberOfChildren
                  costPerChildShort
                  numberOfPeople
                  costPerPersonShort
                  numberOfVehicles
                  costPerVehicleShort
                  time
                  addButton
                  total
                  continueButton
                  contactUs
                }
              }
            }
            allDatoCmsBooking {
              edges {
                node {
                  locale
                  bookingButton
                  bookingButtonShort
                  promoCode
                  promoCodePremature
                  promoCodePrematureRemove
                  promoCodeValid
                  promoCodeValidRemove
                  promoCodeInvalid
                  promoCodeInvalidRemove
                  searchLabel
                  inputClear
                  inputApply
                  dates
                  nightsWithNumber
                  guests
                  guestsWithNumber
                  guestsAdults
                  adultsWithNumber
                  guestsChildren
                  childrenWithNumber
                  guestsMax
                  guestsMore
                  vehiclesWithNumber
                  roomsSearching
                  roomsViewRoom
                  resultsSummary
                  resultsRoomRate
                  resultsRoomUnavailable
                  roomSearchPrompt
                  roomLoading
                  termsConditions
                  cancellation
                  guarantee
                  payment
                  taxes
                  perNight
                  bookNow
                  bookingInProgress
                  bookingSummaryTitle
                  total
                  taxesAndFeesNote
                  and
                  remove
                  addARoomPageTitle
                  addARoom
                  addARoomSearching
                  addARoomDetails
                  addARoomGuests
                  addARoomGuestsDescription
                  addARoomFail
                  roomWithNumber
                  addonsSubheading
                  findBookingPageTitle
                  confirmationNumber
                  search
                  searching
                  noReservationFound
                  cancel
                  cancelInProgress
                  cancelNotPossible
                  cancelConfirmation
                  cancelError
                  modify
                }
              }
            }
            allDatoCmsBookingConfirmationMessaging {
              edges {
                node {
                  locale
                  breadcrumbTitle
                  pageTitle
                  paymentDescription
                  servicesNotBooked
                  welcome
                  bookAnotherRoom
                }
              }
            }
            allDatoCmsDetailsMessaging {
              edges {
                node {
                  locale
                  optional
                  breadcrumbTitle
                  pageTitle
                  personalDetailsFullName
                  personalDetailsPhoneNumber
                  personalDetailsEmailAddress
                  personalDetailsEmailAddressHint
                  addressTitle
                  addressLine1
                  addressAddSecondLine
                  addressAddCompanyName
                  addressCompanyName
                  addressBusinessStay
                  addressCity
                  addressPostalCode
                  addressCountry
                  additionalDetailsTitle
                  additionalDetailsArrivalTime
                  additionalDetailsAddSpecialRequests
                  additionalDetailsSpecialRequests
                  creditCardTitlePayment
                  creditCardTitleGuarantee
                  creditCardDescriptionNonRefundable
                  creditCardDescriptionGuarantee
                  creditCardNumber
                  creditCardExpiration
                  creditCardSecurityCode
                  creditCardName
                  bookingConditionsTitle
                  bookingConditionsCheckInTitle
                  bookingConditionsCheckInText
                  bookingConditionsCheckOutTitle
                  bookingConditionsCheckOutText
                  acknowledgementTitle
                  acknowledgementPrivacyPolicy
                  acknowledgementTermsConditions
                  submitButton
                  submitButtonInProgress
                  errorRequired
                  errorInvalidEmailAddress
                  errorInvalidCreditCardNumber
                  errorInvalidExpiryDate
                  errorInvalidSecurityCode
                }
              }
            }
            allDatoCmsGlobalLabeling {
              edges {
                node {
                  locale
                  menuButton
                  menuButtonClose
                  genericErrorWithRetry
                  retry
                  moreDetails
                  availablePositions
                  applyHere
                  notFound
                  cantFindPage
                  helpfulLinks
                  restaurantLink
                  whatsOnLink
                  suggestedRoomsTitle
                  otherRoomsTitle
                  viewEventSpace
                  relatedEventsTitle
                  moreInfo
                  close
                  detailsLabel
                }
              }
            }
          }`}
        render={(data) => {
          const { locales } = data.datoCmsSite;
          const { defaultLangKey } = data.site.siteMetadata;

          const url = canUseDOM ? window.location.pathname : '';
          const langKey = getCurrentLangKey(locales, defaultLangKey, url);

          const addOnsMessages = getSingletonForLang(data.allDatoCmsAddOnsMessaging, langKey);
          const bookingMessages = getSingletonForLang(data.allDatoCmsBooking, langKey);
          const confirmationMessages = getSingletonForLang(data.allDatoCmsBookingConfirmationMessaging, langKey);
          const detailsMessages = getSingletonForLang(data.allDatoCmsDetailsMessaging, langKey);
          const globalMessages = getSingletonForLang(data.allDatoCmsGlobalLabeling, langKey);

          const messages = getMessages({
            addons: addOnsMessages,
            booking: bookingMessages,
            confirmation: confirmationMessages,
            details: detailsMessages,
            global: globalMessages,
          });

          return (
            <OuterWrapper>
              <Helmet>
                <html lang={langKey} />
              </Helmet>
              <TransitionGroup component={null}>
                <CSSTransition
                  key={location.pathname}
                  classNames="page-transition"
                  timeout={{ enter: TRANSITION_TIME_TOTAL, exit: TRANSITION_TIME_TOTAL }}
                >
                  <TransitionHandler location={location}>
                    <IntlProvider key={langKey} locale={langKey} messages={messages}>
                      {children}
                    </IntlProvider>
                  </TransitionHandler>
                </CSSTransition>
              </TransitionGroup>
            </OuterWrapper>
          );
        }}
      />
    );
  }
}

AppLayout.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  updateWindowSize: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateWindowSize: (payload) => dispatch({ type: WINDOW_RESIZE, payload }),
});

export default connect(null, mapDispatchToProps)(AppLayout);
