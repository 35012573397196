// Watchers
export const ROUTER_LOCATION_CHANGED = 'ROUTER_LOCATION_CHANGED';
export const SEARCH_PARAMS_UPDATED = 'SEARCH_PARAMS_UPDATED';
export const SELECT_STAY = 'SELECT_STAY';

// Availability
export const FETCH_AVAILABILITY_REQUESTED = 'FETCH_AVAILABILITY_REQUESTED';
export const FETCH_AVAILABILITY_SUCCEEDED = 'FETCH_AVAILABILITY_SUCCEEDED';
export const FETCH_AVAILABILITY_FAILED = 'FETCH_AVAILABILITY_FAILED';
export const FETCH_AVAILABILITY_RETRIED = 'FETCH_AVAILABILITY_RETRIED';

// General Availability
export const FETCH_GENERAL_AVAILABILITY_SUCCEEDED = 'FETCH_GENERAL_AVAILABILITY_SUCCEEDED';
export const FETCH_GENERAL_AVAILABILITY_FAILED = 'FETCH_GENERAL_AVAILABILITY_FAILED';

// Info
export const FETCH_INFO_SUCCEEDED = 'FETCH_INFO_SUCCEEDED';
export const FETCH_INFO_FAILED = 'FETCH_INFO_FAILED';

// Additional Rooms
export const FETCH_ADDITIONAL_AVAILABILITY_SUCCEEDED = 'FETCH_ADDITIONAL_AVAILABILITY_SUCCEEDED';
export const FETCH_ADDITIONAL_AVAILABILITY_FAILED = 'FETCH_ADDITIONAL_AVAILABILITY_FAILED';
export const ADD_ROOM_REQUESTED = 'ADD_ROOM_REQUESTED';
export const ADD_ROOM_ALLOWED = 'ADD_ROOM_ALLOWED';
export const ADD_ROOM_DENIED = 'ADD_ROOM_DENIED';

// Services
export const FETCH_SERVICES_REQUESTED = 'FETCH_SERVICES_REQUESTED';
export const FETCH_SERVICES_SUCCEEDED = 'FETCH_SERVICES_SUCCEEDED';
export const FETCH_SERVICES_FAILED = 'FETCH_SERVICES_FAILED';
export const FETCH_SERVICES_RETRIED = 'FETCH_SERVICES_RETRIED';
export const ADD_SERVICE = 'ADD_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const SELECT_SERVICES = 'SELECT_SERVICES';

// Booking
export const BOOKING_UPDATE_STAY = 'BOOKING_UPDATE_STAY';
export const BOOKING_ADD_ROOM_REQUESTED = 'BOOKING_ADD_ROOM_REQUESTED';
export const BOOKING_ADD_ROOM_SUCCEEDED = 'BOOKING_ADD_ROOM_SUCCEEDED';
export const BOOKING_ADD_ROOM_FAILED = 'BOOKING_ADD_ROOM_FAILED';
export const BOOKING_ADD_SERVICE = 'BOOKING_ADD_SERVICE';
export const BOOKING_REMOVE_SERVICE = 'BOOKING_REMOVE_SERVICE';
export const BOOKING_SELECT_SERVICES = 'BOOKING_SELECT_SERVICES';
export const BOOKING_SUBMIT_DETAILS_FORM = 'BOOKING_SUBMIT_DETAILS_FORM';
export const MAKE_BOOKING_REQUESTED = 'MAKE_BOOKING_REQUESTED';
export const MAKE_BOOKING_SUCCEEDED = 'MAKE_BOOKING_SUCCEEDED';
export const MAKE_BOOKING_FAILED = 'MAKE_BOOKING_FAILED';

// Post Booking
export const BOOKING_SUBMIT_SEARCH_FORM = 'BOOKING_SUBMIT_SEARCH_FORM';
export const FETCH_BOOKING_REQUESTED = 'FETCH_BOOKING_REQUESTED';
export const FETCH_BOOKING_NOT_FOUND = 'FETCH_BOOKING_NOT_FOUND';
export const FETCH_BOOKING_SUCCEEDED = 'FETCH_BOOKING_SUCCEEDED';
export const FETCH_BOOKING_FAILED = 'FETCH_BOOKING_FAILED';
export const CANCEL_BOOKING_REQUESTED = 'CANCEL_BOOKING_REQUESTED';
export const CANCEL_BOOKING_SUCCEEDED = 'CANCEL_BOOKING_SUCCEEDED';
export const CANCEL_BOOKING_FAILED = 'CANCEL_BOOKING_FAILED';

// Booking Widget
export const BOOKING_WIDGET_CLEAR_DATES = 'BOOKING_WIDGET_CLEAR_DATES';
export const BOOKING_WIDGET_UPDATE_PARAMS = 'BOOKING_WIDGET_UPDATE_PARAMS';
export const BOOKING_WIDGET_UPDATE_PROMO_CODE = 'BOOKING_WIDGET_UPDATE_PROMO_CODE';

// UI
export const CLOSE_NAV = 'CLOSE_NAV';
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const SHOW_ERROR = 'SHOW_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';
export const WINDOW_RESIZE = 'WINDOW_RESIZE';
