/* eslint-disable no-console */
import { delay } from 'redux-saga';

import { all, call, spawn } from 'redux-saga/effects';

import bookingSaga from './booking';


const makeRestartable = (saga) => function* makeImmortal() {
  yield spawn(function* immortalSaga() {
    while (true) {
      try {
        yield call(saga);
        console.error('Unexpected root saga termination. Root sagas should live during the whole app lifetime!', saga);
      } catch (e) {
        console.error('Saga error: the saga will be restarted', e);
      }
      yield delay(1000);
    }
  });
};

const rootSagas = [bookingSaga].map(makeRestartable);

export default function* rootSaga() {
  yield all(rootSagas.map((saga) => call(saga)));
}
